import acpPaywallCipDirective from './acp-paywall-cip-directive';
import acpComponentContactCs from 'components/contact-cs';
import acpCore from 'core';
import ng from 'angular';

/**
 * @acpdoc component
 * @ngdoc module
 * @name acp.component.paywall-cip
 *
 * @requires acp.core
 *
 * @description
 *
 * @example
 * ```html
 * <acp-paywall-cip></acp-paywall-cip>
 * ```
 *
 * ```js
 * angular.module('acp.component.paywall-cip.example', [
 *   require('acp.component.paywall-cip').name
 * ]);
 * ```
 */
var component = ng
  .module('acp.component.paywall-cip', [
    acpCore.name,
    acpComponentContactCs.name
  ])
  .directive('acpPaywallCip', acpPaywallCipDirective);

export default component;

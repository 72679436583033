import acpPaywallCipTemplate from './templates/acp-paywall-cip.html';
// @ngInject
function acpPaywallCipDirective() {
  return {
    restrict: 'E',
    scope: {},
    template: acpPaywallCipTemplate
  };
}

export default acpPaywallCipDirective;
